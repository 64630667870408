import React, { useEffect, useRef, useState } from 'react';
import ShortText from '../Components/ShortText';
import Item from './DropdownItem';
import styles from './Dropdown.module.css';
import TextInput from '../Components/TextInput';
import { useWindowSize } from '../../utils/helpers';
import TextOverflow from '../Components/TextOverflow';

export default function Dropdown({
  height, // height prop must be given as string input such as "5rem" or "50px"
  list, // list array contains item objects, only mandatory field is name: shown title of the item
  isSearchable, // search is possible in the given list, textInput replaces div when dropdown is open
  isCancellable, // cancel function sets selected item to null, cross is visible
  openItemCount = 2, // number of items shown in the list
  placeholder = 'Select one', // placeholder when dropdown is open
  title, // always visible (label that goes upside)
  disabled = false, // if disabled, dropdown is not clickable
  searchBarPlaceholder = 'Search...',
  defaultSelectedIndex, // add this if there is an already selected item
  setSelectedOption = () => {}, // to pass the selected item to parent
  selectedOption,
  // style props
  searchStyle, // style for TextInput being used as searchbar
  headerStyle, // style for header of
  listStyle, // style for menu
  crossOnClick = () => {}, // additional function call to be reached from parent on cross click
  selectOnClick = () => {}, // additional function call to be reached from parent on select
  wrapperStyle = {},
  overflowOption = false, // If passed, wrapperId should also be given
  wrapperId,
  dropdownOpenNotifier = () => {}
}) {
  useEffect(() => {
    console.log("list", list)
  },[list])
  const [open, setOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [selected, setSelected] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [divHeight, setDivHeight] = useState('50');
  const [screenWidth] = useWindowSize();

  const wrapperRef = useRef();
  const crossRef = useRef();
  const mainRef = useRef();

  useEffect(() => {
    if (defaultSelectedIndex && list) {
      /* To be able to pass first index as default selected - set argument as -1 / as it perceives 0 as false */
      setSelected(list[defaultSelectedIndex !== -1 ? defaultSelectedIndex : 0]);
      selectOnClick(list[defaultSelectedIndex !== -1 ? defaultSelectedIndex : 0]);
    }
  }, [defaultSelectedIndex, list]);

  useEffect(() => {
    if (selectedOption) {
      setSelected(selectedOption);
    } else {
      setSelected(null);
    }
  }, [selectedOption]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
          dropdownOpenNotifier(false);
          setSearchActive(false);
          setSearchParam('');
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  function handleClickDiv(event) {
    if (!searchActive && crossRef.current) {
      // in order not to open dropdown on cross click
      if (!crossRef.current.contains(event.target)) {
        setOpen(!open);
        dropdownOpenNotifier(prev => !prev);
      }
    } else if (!searchActive && !crossRef.current) {
      setOpen(!open);
      dropdownOpenNotifier(prev => !prev);
    }
  }

  useEffect(() => {
    if (mainRef.current) {
      setTimeout((  ) => {
        const { height } = mainRef.current.getBoundingClientRect();
      }, 200)
      setDivHeight(height);
    }
  }, [mainRef, screenWidth]);

  useEffect(() => {
    if (selected) {
      setSelectedOption(selected);
    }
  }, [selected]);

  return (
    <div className={styles.wrapper} style={wrapperStyle} ref={wrapperRef} id={wrapperId}>
      {isSearchable && open ? (
        <div>
          <TextInput
            inputstyle={{ ...searchStyle, height: height }}
            autofocus
            placeholder={searchBarPlaceholder}
            value={searchParam}
            onClick={() => {
              setSearchActive(true);
            }}
            onChange={e => {
              const value = e.target.value || '';
              setSearchParam(value);
            }}
          />
        </div>
      ) : (
        <div
          aria-disabled={disabled || list?.length === 0}
          style={{ ...headerStyle, height: height }}
          ref={mainRef}
          onClick={e => handleClickDiv(e)}
          className={`${styles['dd-container']} 
            ${!open && selected === null && styles.closeNoSelect} 
            ${!open && selected !== null && styles.closeSelect}
            ${open && selected === null && styles.openNoSelect}
            ${open && selected !== null && styles.openSelect}`}
        >
          {(open || selected !== null) && (
            <div
              className={styles.placeholder}
              style={{
                ...wrapperStyle,
                justifyContent: 'space-between',
                paddingTop: title ? '' : '0.5rem',
                width: `calc(${wrapperStyle.width} - 40px)`
              }}
            >
              {overflowOption ? (
                <TextOverflow text={selected ? selected.name : placeholder} />
              ) : (
                <ShortText staticLimit={25} name={selected ? selected.name : placeholder} />
              )}
            </div>
          )}
          <label className={styles.title}>{title}</label>
          <div className={styles.iconContainer}>
            {isCancellable && selected && (
              <span
                ref={crossRef}
                alt=""
                className={styles.cross}
                onClick={() => {
                  setSelected(null);
                  crossOnClick();
                }}
              />
            )}
            <span alt="" className={open ? styles.arrowUp : styles.arrowDown} />
          </div>
        </div>
      )}

      {open &&
        list?.filter(x =>
          x.name?.toLocaleLowerCase('tr')?.includes(searchParam?.toLocaleLowerCase('tr'))
        )?.length !== 0 && (
          // this is the scrollable item container part (list)
          <div
            style={{
              ...listStyle,
              height:
                list?.filter(x =>
                  x.name?.toLocaleLowerCase('tr')?.includes(searchParam?.toLocaleLowerCase('tr'))
                )?.length < openItemCount
                  ? `${
                      divHeight *
                        list?.filter(x =>
                          x.name
                            ?.toLocaleLowerCase('tr')
                            ?.includes(searchParam?.toLocaleLowerCase('tr'))
                        )?.length +
                      4
                    }px`
                  : `${divHeight * openItemCount + 4}px`
            }}
            className={styles.list}
            onClick={e => e.stopPropagation()}
          >
            {list?.map((item, index) => (
              <div id="listItem">
                {item?.name
                  ?.toLocaleLowerCase('tr')
                  ?.includes(searchParam.toLocaleLowerCase('tr')) && (
                  <Item
                    height={height}
                    key={index} // key is necessary to iterate list items
                    item={item} // object is directly passed to the item component with every prop
                    first={index === 0} // to give appropriate styling
                    last={index === list.length - 1} // to give appropriate styling
                    selected={index === list.findIndex(x => x === selected)}
                    // to give appropriate styling
                    onSelect={() => {
                      // on select is triggered with onClick of the item
                      setSearchParam('');
                      setOpen(false);
                      dropdownOpenNotifier(false);
                      setSelected(item);
                      selectOnClick(item);
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import {
  addCandidatesToFunnelAction,
  addNewFunnelAction,
  deleteFunnelAction,
  getAssessmentDetailVol2Action,
  getCandidatesByFilterAction,
  getCandidatesByFilterAfterMoveToFunnelAction,
  getFunnelInformationAction,
  getRejectionReasonsAction,
  updateFunnelNameAction,
  updateFunnelPriorityAction
} from './actions';
import initialState from './initialState';
import { getParam, urlDynamicQueryUpdateLite } from '../../utils/helpers';

const assessmentDetailVol2Slice = createSlice({
  name: 'assessmentDetailVol2',
  initialState,
  reducers: {
    /* Filter related setters */
    setSearchParam: (state, action) => {
      state.searchParameter = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setFunnelId: (state, action) => {
      state.funnelId = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setStartScore: (state, action) => {
      state.startScore = action.payload;
    },
    setEndScore: (state, action) => {
      state.endScore = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setArriveType: (state, action) => {
      state.arriveType = action.payload;
    },
    setIndustryList: (state, action) => {
      state.industryList = action.payload;
    },
    setSchoolList: (state, action) => {
      state.schoolList = action.payload;
    },
    setMajorList: (state, action) => {
      state.majorList = action.payload;
    },
    setIndustryIncludeType: (state, action) => {
      state.industryIncludeType = action.payload;
    },
    setScreeningExperiences: (state, action) => {
      state.screeningExperiences = action.payload;
    },
    setScreeningWorkTypes: (state, action) => {
      state.screeningWorkTypes = action.payload;
    },
    setScreeningNationalities: (state, action) => {
      state.screeningNationalities = action.payload;
    },
    setScreeningLocations: (state, action) => {
      state.screeningLocations = action.payload;
    },
    setScreeningSalaryCurrency: (state, action) => {
      state.screeningSalaryCurrency = action.payload;
    },
    setScreeningSalaryMinValue: (state, action) => {
      state.screeningSalaryMinValue = action.payload;
    },
    setScreeningSalaryMaxValue: (state, action) => {
      state.screeningSalaryMaxValue = action.payload;
    },

    setActiveFilterCount: (state, action) => {
      state.activeFilterCount = action.payload;
    },
    setRejectReasonListUrl: (state, action) => {
      state.rejectReasonListUrl = action.payload;
    },
    /* ---------------------------------- */

    /* Assessment Detail related setters */
    setCompanyAssessmentName: (state, action) => {
      state.companyAssessmentName = action.payload;
    },
    setAssessmentObjStatus: (state, action) => {
      state.assessmentObjStatus = action.payload;
    },
    /* ---------------------------------- */

    /* Candidate List related setters */
    setCandidateList: (state, action) => {
      state.candidateList = action.payload;
    },
    setSelectedCandidateList: (state, action) => {
      state.selectedCandidateList = action.payload;
    },
    setBlurCandidateWrapperAfterMoveFlag: (state, action) => {
      state.blurCandidateWrapperAfterMoveFlag = action.payload;
    },
    /* 
      Carried the logic into candidateListComponent 
    */
    // addRemoveCandidateFromSelectedCandidateList: (state, action) => {
    //   const currentList = state.selectedCandidateList;
    //   const index = candidateFindIndex(currentList, action.payload);
    //   if (index !== -1) {
    //     currentList.splice(index, 1);
    //     state.selectedCandidateList = currentList;
    //   } else {
    //     state.selectedCandidateList = [...state.selectedCandidateList, action.payload];
    //   }
    // },

    resetCandidateListRelatedStuffForNewFilters: state => {
      state.candidateList = [];
      state.selectedCandidateList = [];
      state.isMoveEnabledFlag = false;
      state.candidateCountWithCurrentFilter = 0;
      state.loadingForFirstFetch = true;
      urlDynamicQueryUpdateLite('pageNumber', '0', false);
      state.selectedAcceptedCandidateList = [];
    },
    resetCandidateListRelatedStuffForNewFiltersWithoutPageNumber: state => {
      state.candidateList = [];
      state.selectedCandidateList = [];
      state.isMoveEnabledFlag = false;
      state.candidateCountWithCurrentFilter = 0;
      state.loadingForFirstFetch = true;
      state.selectedAcceptedCandidateList = [];
    },
    /* ---------------------------------- */

    /* Move candidates to funnel related setters */
    setIsMoveEnabledFlag: (state, action) => {
      state.isMoveEnabledFlag = action.payload;
    },
    /* ---------------------------------- */
    setFunnelOpen: (state, action) => {
      state.funnelOpen = action.payload;
    },

    setFunnelInfo: (state, action) => {
      state.funnelInfo = action.payload;
    },

    /* Popup related setters */
    setPopupShowState: (state, action) => {
      state.popupShowState = action.payload;
    },
    setPopupTypeState: (state, action) => {
      state.popupTypeState = action.payload;
    },
    setClickedFunnel: (state, action) => {
      state.clickedFunnel = action.payload;
    },

    setRejectModalState: (state, action) => {
      state.rejectModalState = action.payload;
    },
    setRejectModalSelectedCount: (state, action) => {
      state.rejectModalSelectedCount = action.payload;
    },
    setRejectSuccesState: (state, action) => {
      state.rejectSuccessState = action.payload;
    },

    setInfoBannerShowState: (state, action) => {
      state.infoBannerShowState = action.payload;
    },
    setInfoBannerText: (state, action) => {
      state.infoBannerText = action.payload;
    },
    setInfoBannerErrorFlag: (state, action) => {
      state.infoBannerErrorFlag = action.payload;
    },

    /* ---------------------------------- */

    setShowMailModal: (state, action) => {
      state.showMailModal = action.payload;
    },
    setMailModalClickedFunnelInfo: (state, action) => {
      state.mailModalClickedFunnelInfo = action.payload;
    }
  },

  extraReducers: builder => {
    /* Company Assessment Detail reducers */
    builder.addCase(getAssessmentDetailVol2Action.pending, state => {
      state.industryAvg = null;
      state.top5Avg = null;
      state.assessmentObjStatus = null;
      state.rootAssessmentName = null;
      state.rating = null;
      state.companyAssessmentName = null;
      state.fiveCandidatesExistFlag = null;
      state.assessmentDetailLoading = true;
      state.authFlag = null;
      state.isCustomFlag = null;
      state.assessmentDetailObj = null;
      state.rootAssessmentId = null;
    });

    builder.addCase(getAssessmentDetailVol2Action.fulfilled, (state, action) => {
      state.industryAvg = action.payload.allScoreAverage;
      state.top5Avg = action.payload.top5ScoreAverage;
      state.assessmentObjStatus = action.payload.assessmentStatus;
      state.rootAssessmentName = action.payload.category;
      state.rating = action.payload.companyAssessmentStatus;
      state.companyAssessmentName = action.payload.title;
      state.fiveCandidatesExistFlag = action.payload.fiveCandidatesExist;
      state.assessmentDetailLoading = false;
      state.authFlag = true;
      state.isCustomFlag = action.payload.isCustomAssessment;
      state.assessmentDetailObj = action.payload;
      state.rootAssessmentId = action.payload?.rootAssessmentId;
    });

    builder.addCase(getAssessmentDetailVol2Action.rejected, state => {
      state.assessmentDetailLoading = false;
      state.authFlag = false;
      state.assessmentDetailObj = null;
    });

    /* ----------------------------------------------------- */

    /* Candidate List related reducers */
    builder.addCase(getCandidatesByFilterAction.fulfilled, (state, action) => {
      const funnelIdUrl = getParam(window.location.search, 'funnelId');

      if (action.meta.arg.funnelId === funnelIdUrl) {
        state.candidateList = [
          ...state.candidateList,
          ...action.payload.companyAssessmentCandidateDataList
        ];
        state.candidateCountWithCurrentFilter = action.payload.totalItem;
        state.oldestInitialDate = action.payload.oldestCandidateDate;
        state.loadingForFirstFetch = false;
      }
      state.loadingForScrollCandidates = false;
      state.newOpMixpanelData = action?.payload?.mixPanelData;
    });
    builder.addCase(getCandidatesByFilterAction.pending, state => {
      state.loadingForScrollCandidates = true;
      state.newOpMixpanelData = null;
    });
    builder.addCase(getCandidatesByFilterAfterMoveToFunnelAction.fulfilled, (state, action) => {
      state.candidateList = action.payload.companyAssessmentCandidateDataList;
      state.loadingForScrollCandidates = false;
      state.blurCandidateWrapperAfterMoveFlag = false;
    });
    /* ----------------------------------------------------- */

    /* Funnel Information */

    builder.addCase(getFunnelInformationAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
      state.funnelLoading = false;
    });
    builder.addCase(getFunnelInformationAction.pending, state => {
      state.funnelLoading = true;
    });

    builder.addCase(addCandidatesToFunnelAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
      state.isMoveEnabledFlag = false;
      state.selectedCandidateList = [];
      state.selectedAcceptedCandidateList = [];
    });

    builder.addCase(addNewFunnelAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
    });
    builder.addCase(deleteFunnelAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
    });
    builder.addCase(updateFunnelNameAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
    });
    builder.addCase(updateFunnelPriorityAction.fulfilled, (state, action) => {
      state.funnelInfo = action.payload.funnels;
    });
    builder.addCase(getRejectionReasonsAction.fulfilled, (state, action) => {
      state.rejectReasonListFromApi = action.payload;
    });

    /* ----------------------------------------------------- */
  }
});

export const {
  setSearchParam,
  setOrderType,
  setFunnelId,
  setStartDate,
  setEndDate,
  setStartScore,
  setEndScore,
  setPageNumber,
  setArriveType,
  setIndustryList,
  setSchoolList,
  setIndustryIncludeType,
  setScreeningExperiences,
  setScreeningWorkTypes,
  setScreeningNationalities,
  setScreeningLocations,
  setScreeningSalaryCurrency,
  setScreeningSalaryMinValue,
  setScreeningSalaryMaxValue,
  setRejectReasonListUrl,

  setCandidateList,
  setSelectedCandidateList,
  addRemoveCandidateFromSelectedCandidateList,
  setIsMoveEnabledFlag,
  resetCandidateListRelatedStuffForNewFilters,
  resetCandidateListRelatedStuffForNewFiltersWithoutPageNumber,
  setFunnelOpen,
  setActiveFilterCount,
  setPopupShowState,
  setPopupTypeState,
  setClickedFunnel,
  setRejectModalState,
  setRejectModalSelectedCount,
  setRejectSuccesState,
  setInfoBannerShowState,
  setInfoBannerText,
  setInfoBannerErrorFlag,
  setCompanyAssessmentName,
  setAssessmentObjStatus,
  setBlurCandidateWrapperAfterMoveFlag,
  setFunnelInfo,
  setMajorList,

  setShowMailModal,
  setMailModalClickedFunnelInfo
} = assessmentDetailVol2Slice.actions;

export default assessmentDetailVol2Slice.reducer;

import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/AssessmentBody.module.css';
import { getQuestionDetailAction } from '../../../redux/assessmentsVol2/actions';
import { questionDetail } from '../../../redux/assessmentsVol2/selectors';
import {
  CodingQuestion,
  FreeTextQuestions,
  MultipleChoiceQuestion
} from '../../AddAssessment/Components/AssessmentQuestion';
import PageContainer from '../../../Common/Pages/PageContainer';

export default function Question() {
  const dispatch = useDispatch();
  const params = useParams();
  const getQuestion = useSelector(questionDetail);
  const [compKey, setCompKey] = useState(0);
  const [editorState, setEditorState] = React.useState(null);

  const { t } = useTranslation(['tryAssm'])

  useEffect(() => {
    dispatch(getQuestionDetailAction({ QuestionId: params.id }));
  }, [params.id]);

  useEffect(() => {
    if (getQuestion && getQuestion.questionDescription) {
      try {
        let questionBody = JSON.parse(getQuestion?.questionDescription);
        questionBody = questionBody.replaceAll('https://api.coens.io/', '');
        questionBody = questionBody.replaceAll('https://file.coens.io/', '');
        questionBody = questionBody.replaceAll(
          '<img src="',
          `<img src="${process.env.REACT_APP_IMAGE_API}/`
        );
        questionBody = questionBody.replaceAll(
          '<source src="',
          `<source src="${process.env.REACT_APP_IMAGE_API}/`
        );
        setEditorState(questionBody);
        setCompKey(compKey + 1);
      } catch (error) {
        console.log(error);
      }
    }
  }, [getQuestion]);

  const displayQuestion = type => {
    switch (type) {
      case 1:
        return (
          <MultipleChoiceQuestion
            category={getQuestion.category}
            title={getQuestion.title}
            time={getQuestion.time}
            mcOptions={getQuestion.option}
            qDesc={editorState}
            difficulty={getQuestion.difficulty}
            imageOption={getQuestion.imageOption}
          />
        );
      case 2:
        return (
          <CodingQuestion
            category={getQuestion.category}
            title={getQuestion.title}
            time={getQuestion.time}
            qDesc={editorState}
            difficulty={getQuestion.difficulty}
          />
        );
      case 3:
        return (
          <FreeTextQuestions
            category={getQuestion.category}
            title={getQuestion.title}
            time={getQuestion.time}
            qDesc={editorState}
            difficulty={getQuestion.difficulty}
          />
        );
      default:
        return null;
    }
  };

  return getQuestion ? (
    <PageContainer>
      <div className={styles.contentBody}>
        <div className={styles.question}>
          <div className={styles.questionWrapper}>
            <div className={styles.title}>{t('qPreview').toLocaleUpperCase('tr')}</div>
            {getQuestion && displayQuestion(getQuestion.questionTypeId)}
          </div>
        </div>
      </div>
    </PageContainer>
  ) : null;
}

const candMail = localStorage.getItem('candidateEmail');
const sessionToken = localStorage.getItem('assessmentSessionToken');
const compName = localStorage.getItem('companyName');

export default {
  cvCheck: null,
  screeningCheck: null,
  logoPath: null,
  assessmentTitle: null,
  companyName: compName || null,
  questionCount: null,
  totalTime: null,
  candidateEmail: candMail || null,
  showContract: true,
  multiCount: 0,
  cvPath: null,
  freeCount: 0,
  languageList: null,
  candidateName: '',
  candidatePhone: null,
  candidateLinkedIn: '',
  candidateSurname: '',
  codingCount: 0,
  assessmentSessionToken: sessionToken || null,
  questionInfo: null,
  nextButtonLoading: false,
  loading: true,
  applicationState: 'email',
  saveLoading: false,
  showBanner: false,
  bannerText: 'Answer is automatically submitted',
  expired: false,
  rootAssessmentName: null,

  // Screening Related
  screeningLoading: false,
  screeningListObj: null,

  screeningWorkTypeList: null,
  screeningSalaryExpectation: null,
  screeningNationalityIds: null,
  screeningCurrencyType: null,
  screeningExperience: null,

  screeningLocation: null,
  hasAssessment: null,

  hasInformation: null
};

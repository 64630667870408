import axios from 'axios';
import config from './configVol2';
import { logoutAndRedirect } from '../utils/helpers';

const api = axios.create({
  baseURL: config.BASE_URL
});

api.interceptors.request.use(
  config => {
    const authToken = localStorage.getItem('authTokenVol2');
    const lng = localStorage.getItem('i18nextLng');

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    config.headers['access-control-allow-origin'] = '*';
    config.headers['access-control-allow-methods'] = '*';
    config.headers['access-control-allow-headers'] =
      'Origin, X-Requested-With, Content-Type, Accept, Authorization';
    config.headers['Accept-Language'] = lng === 'tr' ? 'tr' : 'en';
    return config;
  },
  error => Promise.reject(error?.response?.data)
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401 && window.location.pathname !== '/login') {
      logoutAndRedirect();
    }

    if (error.response) {
      return Promise.reject(error.response?.data);
    }
    return Promise.reject(error);
  }
);

export default api;

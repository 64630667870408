export default {
  login: '/login',
  activate: '/Login/UserActivate',
  refreshPassword: '/Login/RefreshPassword',
  userActivate: '/Authorazation/CompanyUserActivate',
  companyUserActivate: '/Authorization',
  register: '/register',
  forgotPassword: '/forgot-password',
  welcomeCandidate: '/welcome-candidate',
  candidateCongratulation: '/candidate-congratulation',
  addAssessment: '/add-assessment',
  assessments: '/assessments',
  candidateResult: '/candidate-result',
  assessmentDetail: (id = ':id') => `/assessment-detail/${id}`,
  assessmentPublic: (id = ':id') => `/application/${id}`,
  question: (id = ':id') => `/question/${id}`,
  codingQuestion: '/upiefEshT782Fhe29Xbo7T',
  multiSelectQuestion: '/hfpexT73Bhj2Vn1Epls5U',
  freeTextQuestion: '/rt57Fh9vJkoln45ErYpZ4F',
  sqlQuestion: '/nxTSuk4IcvhoPYoKQ5doQf',
  previewAssessment: '/preview-assessment',
  candidateDetail: (id = ':id') => `/candidate-detail/${id}`,
  assessmentCustomization: (id = ':id') => `/assessment-customization/${id}`,
  questionBasedAssessment: (id = 'id') => `/question-based-assessment/${id}`,
  tryAssessment: (id = 'id') => `/try-assessment/${id}`,
  createAssessment: (index = 'index') => `/create-assessment/${index}`,
  successFactor: id => `/candidate-public?token=${id}`,
  assessmentSessionInformation: '/assessment-session-information',
  assessmentSession: '/assessment-session',
  settings: (tab = ':tab') => `/settings/${tab}`,
  salesScreen: (index = ':index') => `/nRdDOsdIFdwEdfdsaSd/${index}`,
  notFound: '/not-found',
  addPosition: '/coensio-ai',
  assessmentRecord: '/uJQMjYtMgwJYyUSdof90LW',
  elasticRecord: '/egyVnZ62sAiLRi03pMMu',
  adminDashboard: '/NLPpEKGEOHIkXrNKVX8g',
  autoApplyAdmin: '/m3DF88vdL1g8kACTlH9j',
  positionWithoutAssessment: '/pJXTmSBNqusFdllyLnO6',
  autoApplyAdminControl: '/AKrq3ObdDpHlw8s9zHMj',
  survey: (surveyId = ':surveyId', encryptedEmail = ':encryptedEmail') =>
    `/survey/${surveyId}/${encryptedEmail}`,
  insights: '/insights',
  editAssessmentDetails: '/edit-assessment-details',
  isScreeninAdminDashboard: '/isScreening-admin-dashboard',
  cameraControlPanel: '/camera-control-panel',
  caseStudy: (caseStudyId = ':caseStudyId', encryptedEmail = ':encryptedEmail') =>
    `/case/${caseStudyId}/${encryptedEmail}`,
  editCandidateResult: '/edit-candidate-result',
  editCompanyCandidateResult: '/edit-company-candidate-result',
};

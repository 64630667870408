import React from 'react';
import { useLocation } from 'react-router-dom';
import useTranslationDetection from '../../../utils/useTranslationDetection';
import Modal from '../../../Common/CustomModal/Modal';
import constants from '../../../utils/constants';

export default function useDisableOnTranslation() {
  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');

  const assessmentList = constants.TRANSLATE_CHECK_ASSESSMENT_KEYS;
  const isFeatureEnabled = assessmentList?.includes(assessmentId);
  const isTranslateEnabled1 = useTranslationDetection();

  const isTranslateEnabled = isFeatureEnabled && isTranslateEnabled1;

  const DisableModal = () => {
    return (
      <Modal
        centerHorizontally
        centerVertically
        show
        onClose={() => {}}
        contentStyle={{ borderRadius: '10px', padding: '18px 24px', maxWidth: '635px' }}
        contentClassName="FlexColumn gap-16 w-100 fw-400 font-16"
      >
        Please turn off translation services to proceed with the test.
      </Modal>
    );
  };

  return { isTranslateEnabled, DisableModal };
}

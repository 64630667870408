import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { useTranslation, Trans } from 'react-i18next';

import CloseButton from '../../../../Common/Components/CloseButton';
import Checkbox from '../../../../Common/Components/Checkbox';
import CustomButton from '../../../../Common/Components/CustomButton';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import TextInput from '../../../../Common/Components/TextInput';
import Modal from '../../../../Common/CustomModal/Modal';
import Dropdown from '../../../../Common/Dropdown/Dropdown';
import NewRejectionMailTextEditor from './RejectionMailTextEditor/NewRejectionMailTextEditor';
import {
  rejectModalSelectedCountSelector,
  selectedCandidateListSelector
} from '../../../../redux/assessmentDetailVol2/selectors';
import { userSelector } from '../../../../redux/auth/selectors';
import { candidateProfileSelector } from '../../../../redux/candidateDetail/selectors';
import {
  createRejectedEmailContent,
  getCompanySubjectAndEmailContent,
  justSendEmailContentAction,
  sendRejectedEmailContent
} from '../../../../redux/rejectionMail/actions';
import { getRejectionReasonsAction } from '../../../../redux/assessmentDetailVol2/actions';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import styles from './RejectCandidatesModal.module.css';

const RejectCandidatesModal = ({
  show,
  onClose,
  showSuccess,
  setMailSentFlag,
  moveMethod,
  selectedReasonToReject = null,
  setSelectedReasonToReject = () => {},
  newCandidateDetailFlag = false,
  assessmentDetailFlag = false
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [reasonList, setReasonList] = useState([]);
  const [rejectionStep, setRejectionStep] = useState(false);
  const [loadingForButtons, setLoadingForButtons] = useState(false);
  const [justSendButtonLoading, setJustSendButtonLoading] = useState(false);
  
  const { t } = useTranslation(['candidateDetail'])

  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector) || [];
  const allSelectedCandidatesList = [...selectedCandidateListRedux];

  const currentUserRedux = useSelector(userSelector);

  // For Candidate detail - locked check
  const profile = useSelector(candidateProfileSelector);

  const [newTemplateName, setNewTemplateName] = useState('');

  const [templateSubjectField, setTemplateSubjectField] = useState('');
  const [templateMailContent, setTemplateMailContent] = useState('');
  const [companyMailId, setCompanyMailId] = useState();
  const [isNewMail, setIsNewMail] = useState(false);
  const initialDataForMailDropdown = isMobile
    ? []
    : [
        {
          name: t('createTemplate'),
          id: 1,
          // subject: templateSubjectField,
          emailContent: templateMailContent
        }
      ];
  const [mailListForDropdown, setMailListForDropdown] = useState(initialDataForMailDropdown);
  const [selectedMail, setSelectedMail] = useState();
  const [clickedMail, setClickedMail] = useState();

  const initialDelayDataForDelayDropdown = [
    { name: t('now'), id: 1 },
    { name: t('1dayLater'), id: 2 },
    { name: t('2daysLater'), id: 3 },
    { name: t('3daysLater'), id: 4 }
  ];
  const [delayDataForDelayDropdown, setDelayDataForDelayDropdown] = useState(
    initialDelayDataForDelayDropdown
  );
  const [selectedDelay, setSelectedDelay] = useState();

  const fetchMailTemplates = async () => {
    const resp = await dispatch(
      getCompanySubjectAndEmailContent({ CompanyId: currentUserRedux?.companyId })
    );
    const innerMailList = resp?.payload?.data;
    const temp = [];
    innerMailList.forEach(mail => {
      temp.push({
        name: mail.reservedName,
        id: mail.id,
        subject: mail.subject,
        emailContent: mail.emailContent
      });
    });
    setMailListForDropdown(mailListForDropdown => [...mailListForDropdown, ...temp]);
  };

  useEffect(async () => {
    setRejectionStep(false);
    if (show) {
      fetchMailTemplates();
    } else {
      setMailListForDropdown(initialDataForMailDropdown);
    }
    setDelayDataForDelayDropdown(initialDelayDataForDelayDropdown);
  }, [show]);

  const switchToCreateNewTemplate = () => {
    setSelectedMail(initialDataForMailDropdown[0]);
    setCompanyMailId(1);
  };

  useEffect(() => {
    if (templateMailContent !== '' && !isMobile) {
      switchToCreateNewTemplate();
    }
  }, [templateMailContent]);

  useEffect(() => {
    if (clickedMail) {
      setTemplateSubjectField(clickedMail.subject || '');
      setNewTemplateName('');
      setTemplateMailContent('');
    }
  }, [clickedMail]);

  useEffect(() => {
    if (selectedMail?.id === 1) {
      setClickedMail();
      setIsNewMail(true);
    } else {
      setIsNewMail(false);
    }
  }, [selectedMail]);

  useEffect(async () => {
    const resp = await dispatch(getRejectionReasonsAction());
    setReasonList(resp.payload);
  }, []);

  const delayTime = () => {
    switch (selectedDelay.id) {
      case 1:
        return 0;
      case 2:
        return 1440;
      case 3:
        return 2880;
      case 4:
        return 4320;
      default:
        return 0;
    }
  };

  const createMailTemplate = async () => {
    setLoadingForButtons(true);
    const delay = delayTime();
    const data = {
      companyId: currentUserRedux?.companyId,
      subject: templateSubjectField,
      candidateList: !newCandidateDetailFlag ? allSelectedCandidatesList?.map(e => e.userId) : [id],
      candidateEmailSubmissionTime: delay,
      emailContent: templateMailContent,
      title: newTemplateName,
      companyAssessmentId: !newCandidateDetailFlag
        ? id
        : getParam(location.search, 'currentAssessmentId')
    };
    const resp = await dispatch(createRejectedEmailContent(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      fetchMailTemplates();
      const newSelectedMail = {
        name: newTemplateName,
        emailContent: templateMailContent,
        id: resp?.payload?.data?.companyEmailId,
        subject: templateSubjectField
      };
      setSelectedMail(newSelectedMail);
      setCompanyMailId(resp?.payload?.data?.companyEmailId);
    }
    setIsNewMail(false);
    setLoadingForButtons(false);
  };

  const sendMail = async () => {
    setLoadingForButtons(true);
    const delay = delayTime();
    const data = {
      companyEmailId: selectedMail.id ?? companyMailId,
      candidateList: !newCandidateDetailFlag ? allSelectedCandidatesList?.map(e => e.userId) : [id],
      candidateEmailSubmissionTime: delay,
      companyAssessmentId: !newCandidateDetailFlag
        ? id
        : getParam(location.search, 'currentAssessmentId')
    };
    const resp = await dispatch(sendRejectedEmailContent(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      await moveMethod(true);
      setMailSentFlag(true);
      onClose();
      showSuccess(true);
    }
    setLoadingForButtons(false);
  };

  const rejectCandidate = async () => {
    setLoadingForButtons(true);
    // TODO: move method integration
    await moveMethod(false);
    setMailSentFlag(false);
    setNewTemplateName('');
    setTemplateSubjectField('');
    setSelectedMail();
    onClose();
    showSuccess(false);
    setLoadingForButtons(false);
  };

  const handleOnlySend = async () => {
    setJustSendButtonLoading(true);
    const delay = delayTime();
    const data = {
      candidateList: selectedCandidateListRedux?.map(e => e.userId),
      candidateEmailSubmissionTime: delay,
      companyAssessmentId: id,
      emailContent: templateMailContent,
      subject: templateSubjectField
    };
    const resp = await dispatch(justSendEmailContentAction(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      await moveMethod(true);
      setMailSentFlag(true);
      onClose();
      showSuccess(true);
    }

    setJustSendButtonLoading(false);
  };

  const selectedCandCountForReject = useSelector(rejectModalSelectedCountSelector);

  const WebRejectionModal = (
    <Modal
      contentStyle={{
        border: '1px solid rgba(88, 88, 88, 0.2)',
        borderRadius: '24px',
        padding: '2.7rem 4rem 3.2rem 4rem',
        transition: 'max-height 1s',
        maxHeight: rejectionStep ? '1000px' : '450px'
      }}
      onClose={onClose}
      size={{ width: '763px', height: 'fit-content' }}
      centerHorizontally
      centerVertically
      show={show}
      // outsideClickClose
    >
      <div className={styles.contentWrapper}>
        <text className={styles.contentHeader}>{`${
          selectedCandCountForReject > 1 ? (
            // ? `Reject Candidates (${selectedCandCountForReject})`
            <span>
              <Trans
                i18nKey="rejectCandCount"
                ns="candidateDetail"
                values={{ selectedCandidates: selectedCandCountForReject }}
              >
                0<span>1</span>
                <span>2</span>3
              </Trans>
            </span>
          ) : (
            t('rejectCand')
          )
        }  `}</text>
        <div className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </div>

        <Dropdown
          height="4.8rem"
          list={reasonList}
          openItemCount={4}
          placeholder={t('selectRejectionReason')} 
          title={t('rejectionReasonTitle')}
          setSelectedOption={setSelectedReasonToReject}
          selectedOption={selectedReasonToReject}
          wrapperStyle={{ width: '100%' }}
          listStyle={{ maxHeight: '155px' }}
        />
        <span
          style={{
            color: '#7458D3',
            marginTop: '8px',
            marginBottom: '16px',
            fontSize: '14px'
          }}
          className={styles.defaultText}
        >
          {t('notVisibleToCand')}
        </span>

        <div
          style={
            (newCandidateDetailFlag && profile?.status === 4) ||
            (assessmentDetailFlag && allSelectedCandidatesList.every(x => x.objStatusId === 4))
              ? { pointerEvents: 'none', opacity: '0.4' }
              : { borderTop: ' 1px solid rgba(88, 88, 88, 0.2)' }
          }
        >
          <div className={styles.SwitchButtonRow} style={{ marginTop: '16px' }}>
            <Checkbox
              myChecked={rejectionStep}
              myOnClick={() => setRejectionStep(!rejectionStep)}
            />
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setRejectionStep(!rejectionStep)}
              className={styles.defaultText}
            >
              {t('sendRejectionMail')}
            </span>
          </div>
        </div>

        {/* Button Row for rejection step = 0 */}
        <div
          className={styles.ButtonRow}
          style={{
            opacity: !rejectionStep ? '1' : '0',
            visibility: !rejectionStep ? 'visible' : 'hidden',
            maxHeight: !rejectionStep ? '4.2rem' : '0rem',
            marginTop: !rejectionStep ? '16px' : '0rem',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <CustomButton
            textField={t('cancel')}
            customStyle={{ fontSize: '16px' }}
            type={2}
            buttonOnClick={() => onClose()}
            isResponsive
          />
          <CustomButton
            textField={t('rejectButton')}
            customStyle={{ fontSize: '16px' }}
            type={1}
            loading={loadingForButtons}
            buttonOnClick={() => rejectCandidate()}
            isResponsive
          />
        </div>

        {/* rejection step = 1 below */}

        <div
          className={styles.MailPart}
          style={{
            opacity: rejectionStep ? '1' : '0',
            visibility: rejectionStep ? 'visible' : 'hidden',
            maxHeight: rejectionStep ? '80rem' : '0rem',
            marginTop: rejectionStep ? '1.6rem' : '0rem',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <div
            className={styles.DropdownRow}
            style={{
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              marginTop: rejectionStep ? '1.6rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
          >
            <Dropdown
              height="4.8rem"
              list={mailListForDropdown}
              openItemCount={4}
              title={t('mailTemplate')}
              placeholder={t('selectMailTemp')}
              setSelectedOption={setSelectedMail}
              selectedOption={selectedMail}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={selectedMail ? null : 1}
              selectOnClick={setClickedMail}
            />
            <Dropdown
              height="4.8rem"
              list={delayDataForDelayDropdown}
              openItemCount={4}
              title={t('timeToSend')}
              placeholder={t('selectMailTemp')}
              setSelectedOption={setSelectedDelay}
              selectedOption={selectedDelay}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={-1}
              selectOnClick={setSelectedDelay}
            />
          </div>

          {/* newTemplateState conditional part */}
          <TextInput
            style={{
              width: '100%',
              marginTop: rejectionStep && selectedMail?.id === 1 ? '1.2rem' : '0rem',
              maxHeight: rejectionStep && selectedMail?.id === 1 ? '4.8rem' : '0rem',
              opacity: rejectionStep && selectedMail?.id === 1 ? '1' : '0',
              visibility: rejectionStep && selectedMail?.id === 1 ? '1' : '0',
              transition: 'max-height 1s, margin-top 1s, opacity 1s, visibility 1s',
              transitionDelay:
                rejectionStep && selectedMail?.id === 1
                  ? '0s, 0s, 0.2s, 0.2s'
                  : '0.2s, 0.2s, 0s, 0s'
            }}
            value={newTemplateName}
            placeholder={t('nameTemp')}
            onChange={e => setNewTemplateName(e.target.value)}
          />
          {/* ---------------------------------------- */}

          <TextInput
            style={{
              width: '100%',
              marginBottom: rejectionStep ? '1.2rem' : '0rem',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
            value={templateSubjectField}
            placeholder={t('mailSubject')}
            onChange={e => setTemplateSubjectField(e.target.value)}
            disabled={selectedMail?.id !== 1}
          />

          <NewRejectionMailTextEditor
            setStyle={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              maxHeight: rejectionStep ? '50rem' : '0rem',
              transition: 'max-height 1s, opacity 1s, visibility 1s'
            }}
            sampleMail={clickedMail}
            passMailToParent={setTemplateMailContent}
          />
          {/* <CKEditor
            editor={ClassicEditor}
            data={clickedMail.emailContent}
            className={styles.CKEditor}
            config={{

            }}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', editor);
              console.log('clicked mail nedir:', clickedMail);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
              console.log('clicked mail nedir:', clickedMail);
            }}
          /> */}

          <div
            className={styles.ButtonRow}
            style={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              marginTop: rejectionStep ? '3.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.2rem' : '0rem',
              transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
            }}
          >
            <CustomButton
              textField={t('cancel')}
              type={2}
              customStyle={{ fontSize: '16px' }}
              buttonOnClick={() => setRejectionStep(false)}
              isResponsive
            />
            {selectedMail?.id === 1 && (
              <CustomButton
                textField="Just Send"
                type={5}
                loading={justSendButtonLoading}
                customStyle={{ fontSize: '16px' }}
                buttonOnClick={handleOnlySend}
                isResponsive
                isDisabled={
                  selectedMail?.id === 1 &&
                  (templateSubjectField?.trim() === '' ||
                    selectedMail?.emailContent?.trim() === '<p></p>' ||
                    selectedMail?.emailContent?.trim() === '')
                }
              />
            )}
            <CustomButton
              textField={isNewMail ? t('saveAndSend') : t('sendAndReject')}
              type={1}
              loading={loadingForButtons}
              customStyle={{ fontSize: '16px' }}
              buttonOnClick={isNewMail ? createMailTemplate : sendMail}
              isResponsive
              isDisabled={
                selectedMail?.id === 1 &&
                (templateSubjectField?.trim() === '' ||
                  selectedMail?.emailContent?.trim() === '<p></p>' ||
                  selectedMail?.emailContent?.trim() === '' ||
                  newTemplateName?.trim() === '')
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );

  const MobileRejectionModal = (
    <MobileModal
      id="mobile-rejection-modal"
      contentStyle={{
        padding: '20px 20px 24px',
        transition: 'max-height 1s',
        maxHeight: rejectionStep ? '650px' : '350px',
        minWidth: '375px',
        overflow: 'hidden'
      }}
      onClose={onClose}
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      outsideClickClose
    >
      <div className={styles.contentWrapper}>
        <text className={styles.contentHeader}>{`${
          selectedCandCountForReject > 1
            ? <span>
              <Trans
                i18nKey="rejectCandCount"
                ns="candidateDetail"
                values={{ selectedCandidates: selectedCandCountForReject }}
              >
                0<span>1</span>
                <span>2</span>3
              </Trans>
            </span>
            : t('rejectCand')
        }  `}</text>

        <div className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </div>

        <Dropdown
          height="4.8rem"
          list={reasonList}
          openItemCount={4}
          placeholder={t('selectRejectionReason')}
          title={t('rejectionReasonTitle')}
          setSelectedOption={setSelectedReasonToReject}
          selectedOption={selectedReasonToReject}
          wrapperStyle={{ width: '100%' }}
          listStyle={{ maxHeight: '155px' }}
        />
        <span
          style={{
            color: '#7458D3',
            marginTop: '1.8rem',
            marginBottom: '1.8rem',
            fontSize: '14px'
          }}
          className={styles.defaultText}
        >
          {t('notVisibleToCand')}
        </span>
        <div
          className={styles.SwitchButtonRow}
          style={
            (newCandidateDetailFlag && profile?.status === 4) ||
            (assessmentDetailFlag && allSelectedCandidatesList.every(x => x.objStatusId === 4))
              ? { pointerEvents: 'none', opacity: '0.4' }
              : { borderTop: ' 1px solid rgba(88, 88, 88, 0.2)' }
          }
        >
          <div className={styles.SwitchButtonRow} style={{ marginTop: '1.8rem' }}>
            <Checkbox
              myChecked={rejectionStep}
              myOnClick={() => setRejectionStep(!rejectionStep)}
            />
            <span className={styles.defaultText}>{t('sendRejectionMail')}</span>
          </div>
        </div>

        {/* Button Row for rejection step = 0 */}
        <div
          className={styles.ButtonRow}
          style={{
            opacity: !rejectionStep ? '1' : '0',
            visibility: !rejectionStep ? 'visible' : 'hidden',
            maxHeight: !rejectionStep ? '42px' : '0px',
            marginTop: !rejectionStep ? '24px' : '0px',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <CustomButton
            textField={t('cancel')}
            customStyle={{ fontSize: '16px' }}
            type={8}
            buttonOnClick={() => onClose()}
            size="mobile"
          />
          <CustomButton
            textField={t('rejectButton')}
            customStyle={{ fontSize: '16px' }}
            type={1}
            loading={loadingForButtons}
            buttonOnClick={() => rejectCandidate()}
            size="mobile"
          />
        </div>

        {/* rejection step = 1 below */}

        <div
          className={styles.MailPart}
          style={{
            opacity: rejectionStep ? '1' : '0',
            visibility: rejectionStep ? 'visible' : 'hidden',
            maxHeight: rejectionStep ? '840px' : '0px',
            marginTop: rejectionStep ? '16px' : '0px',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <div
            className={styles.DropdownRow}
            style={{
              maxHeight: rejectionStep ? '50px' : '0px',
              marginTop: rejectionStep ? '16px' : '0px',
              transition: 'max-height 1s, margin-top 1s'
            }}
          >
            <Dropdown
              height="50px"
              list={mailListForDropdown}
              openItemCount={4}
              title={t('mailTemplate')}
              placeholder={t('selectMailTemp')}
              setSelectedOption={setSelectedMail}
              selectedOption={selectedMail}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={isMobile ? -1 : 1}
              selectOnClick={setClickedMail}
              overflowOption
              wrapperId="rejectMailDropdownWrapper"
            />
            <Dropdown
              height="50px"
              list={delayDataForDelayDropdown}
              openItemCount={4}
              title={t('timeToSend')}
              placeholder={t('selectMailTemp')}
              setSelectedOption={setSelectedDelay}
              selectedOption={selectedDelay}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={-1}
              selectOnClick={setSelectedDelay}
              overflowOption
            />
          </div>

          {/* ---------------------------------------- */}

          {/* <TextInput
            style={{
              width: '100%',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
            inputstyle={{ borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}
            value={templateSubjectField}
            placeholder="Mail Subject"
            onChange={e => setTemplateSubjectField(e.target.value)}
            disabled={selectedMail?.id !== 1}
          /> */}
          <div
            style={{
              width: 'calc(100% + 2px)',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s',

              border: '1px solid rgba(88, 88, 88, 0.2)',
              borderBottomStyle: 'none',
              margin: '-1px -1px 0px -1px',
              borderRadius: '8px 8px 0px 0px',
              height: '43px',
              padding: '0px 16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <input
              style={{
                width: '100%',
                background: '#ffffff',
                border: 'none'
              }}
              value={templateSubjectField}
              disabled
            />
          </div>

          <NewRejectionMailTextEditor
            setStyle={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              maxHeight: rejectionStep ? '50rem' : '0rem',
              transition: 'max-height 1s, opacity 1s, visibility 1s'
            }}
            sampleMail={clickedMail}
            passMailToParent={setTemplateMailContent}
          />

          <div
            className={styles.ButtonRow}
            style={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              marginTop: rejectionStep ? '24px' : '0rem',
              maxHeight: rejectionStep ? '42px' : '0rem',
              transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
            }}
          >
            <CustomButton
              textField={t('cancel')}
              type={8}
              customStyle={{ fontSize: '16px' }}
              buttonOnClick={() => setRejectionStep(false)}
              size="mobile"
            />
            <CustomButton
              textField={selectedMail?.id === 1 ? t('saveAndSend') : t('sendAndReject')}
              type={1}
              loading={loadingForButtons}
              customStyle={{ fontSize: '16px' }}
              buttonOnClick={selectedMail?.id === 1 ? createMailTemplate : sendMail}
              size="mobile"
              isDisabled={
                selectedMail?.id === 1 &&
                (templateSubjectField?.trim() === '' ||
                  selectedMail?.emailContent?.trim() === '<p></p>' ||
                  selectedMail?.emailContent?.trim() === '' ||
                  newTemplateName?.trim() === '')
              }
            />
          </div>
        </div>
      </div>
    </MobileModal>
  );

  const [screenW] = useWindowSize();

  return screenW > 576 ? WebRejectionModal : MobileRejectionModal;
};
export default RejectCandidatesModal;
